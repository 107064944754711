import Color from "color";

const baseCol = "#d9d9d9";
let step = 0;
const greys = Array.apply(null, Array(43)).map(x => {
    const newCol = Color(baseCol).darken(step).hex();
    step = step + 0.02;

    return newCol;
})

export default greys;

// export default [
//     "#d9d9d9",
//     "#bfbfbf",
//     "#a6a6a6",
//     "#8c8c8c",
//     "#737373",
//     "#595959",
//     "#404040",
//     "#262626",
//     "#0d0d0d"
// ]