import Color from "color";

const baseCol = "#d1ffed";
let step = 0;
const greens = Array.apply(null, Array(43)).map(x => {
    const newCol = Color(baseCol).darken(step).hex();
    step = step + 0.02;

    return newCol;
})

export default greens;