import React from 'react';

const InfectedMapTooltip: React.FC<InfectedMapTooltipProps> = (props) => {
    function renderStandard() {
        return (
            <div className="corona-infected-map-tooltip-inner corona-infected-map-tooltip-label">
                Selecteaza un judet
            </div>
        );
    }

    function renderCounters() {
        return (
            <div className="corona-infected-map-tooltip-inner">
                <div>
                    <strong className="corona-infected-map-tooltip-label">{props.info.county}</strong>
                </div>
                <div>
                    {props.info.total}
                </div>
            </div>
        );
    }

    let content = renderStandard();
    let stateClass = "";

    if (typeof props.info.county === "string" && typeof props.info.total === "number") {
        stateClass = "has-selected-county";
        content = renderCounters();
    }

    let styleSettings: React.CSSProperties = {}

    if (props.info && props.info.color) {
        styleSettings.borderColor = props.info.color;
    }

    return (
        <div className="corona-infected-map-tooltip-container-outer">
            <div
                style={styleSettings}
                className={`corona-infected-map-tooltip-container ${stateClass}`}>
                {content}
            </div>

            <div className={`corona-infected-map-tooltip-container-unknown ${!!props.unknownCases ? "is-visible" : "is-hidden"}`}>
                <div className="corona-infected-map-tooltip-label">
                    Judet necunoscut
                </div>
                <div>
                    {props.unknownCases}
                </div>
            </div>
        </div>
    );
}

export interface InfectedMapTooltipInfo {
    county?: string;
    total?: number;
    color?: string;
}

export interface InfectedMapTooltipProps {
    info: InfectedMapTooltipInfo;
    unknownCases?: number;
}

export default InfectedMapTooltip;