import React, { useState } from 'react';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { Link } from "react-router-dom";


const NavMenu: React.FC<NavMenuProps> = (props) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    function toggleNavstate(event: React.MouseEvent<HTMLElement, MouseEvent>, desiredState?: boolean) {
        if (typeof desiredState === "boolean") {
            setIsMenuOpen(desiredState);
        } else {
            setIsMenuOpen(!isMenuOpen);
        }
    }

    return (
        <div className={`corona-app-navigator-wrap ${isMenuOpen ? "corona-app-navigator-isActive" : "corona-app-navigator-isHidden"}`}>
            <div className="corona-app-navigator-toggler" tabIndex={0} onClick={toggleNavstate}>
                <Icon
                    iconName="GlobalNavButton"
                    className="corona-app-navigator-toggler-icon-burger" />

                <Icon
                    iconName="ChromeClose"
                    className="corona-app-navigator-toggler-icon-close" />
            </div>

            <div className="corona-app-navigator-list-wrap">
                {props.menuItems.map((elem) => {
                    return (
                        <div
                            key={elem.title}
                            className="corona-app-navigator-list-item">
                            <Link to={elem.ruta} onClick={(e) => { toggleNavstate(e, false) }}>
                                {elem.title}
                            </Link>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export interface NavMenuItem {
    title: string;
    ruta: string;
}

export interface NavMenuProps {
    menuItems: NavMenuItem[];
}

export default NavMenu;