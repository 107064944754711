import React, { useContext, useEffect, useState } from 'react';
import "./Charts.css";
import { InfectedData, Infected, DateCountyCounters, AgeGroupSexStat } from '../../providers/InfectedContextTypes';
import { InfectedContext } from '../../providers/InfectedContext';
import LineChart from '../../components/LineChart/LineChart';
import moment from 'moment';
import { LineChartDataSet } from '../../components/LineChart/LineChartTypes';
import Widget from '../../components/Widget/Widget';

const dateFormat = "DD.MM.YYYY";

const ChartsPage: React.FC = () => {
    const infectedContext = useContext<InfectedData>(InfectedContext);

    const [areWidgetsLoading, setAreWidgetsLoading] = useState<boolean>(true);
    const [dateLabels, setDateLabels] = useState<string[]>([]);
    const [casesDataset, setCasesDataset] = useState<number[]>([]);
    const [curedDataset, setCuredDataset] = useState<number[]>([]);
    const [deathDataset, setDeathDataset] = useState<number[]>([]);

    function getTotalLabels(counters: DateCountyCounters[]) {
        if (!(counters && counters.length)) {
            return [] as string[];
        }

        let min: number;
        let max: number;

        counters.forEach(x => {
            const timestemp = moment(x.date).valueOf();

            if (typeof min === "undefined") {
                min = timestemp;
            }

            if (typeof max === "undefined") {
                max = timestemp;
            }

            if (timestemp < min) {
                min = timestemp;
            }

            if (timestemp > max) {
                max = timestemp;
            }
        });

        const startDate = moment(min!);
        const diffDays = moment(max!).diff(startDate, "d", false);

        const datesArr: string[] = [startDate.format(dateFormat)];

        for (let i = 1; i <= diffDays; i++) {
            const currentDate = moment(startDate.valueOf()).add(i, "d");
            datesArr.push(currentDate.format(dateFormat));
        }

        return datesArr;

    }

    function getTotalFromSnapshot(countyCounters: {
        [key: string]: number;
    }) {
        let total = 0;

        Object.keys(countyCounters).forEach(x => {
            total += countyCounters[x];
        });

        return total;
    }

    function getDataSets(dateLabels: string[]) {
        const dateCasesMap: {
            [key: string]: {
                cases: number;
                cured: number;
                deaths: number;
            };
        } = {};

        dateLabels.forEach(x => {
            dateCasesMap[x] = {
                cases: 0,
                cured: 0,
                deaths: 0
            };
        });

        // cases
        [...infectedContext.cases.countyCounters, ...infectedContext.cases.unknownCountyCounters].forEach(x => {
            const dateKey = moment(x.date).format(dateFormat);
            dateCasesMap[dateKey].cases += getTotalFromSnapshot(x.countyCounters);
        });

        // cured
        [...infectedContext.cured.countyCounters, ...infectedContext.cured.unknownCountyCounters].forEach(x => {
            const dateKey = moment(x.date).format(dateFormat);
            dateCasesMap[dateKey].cured += getTotalFromSnapshot(x.countyCounters);
        });

        // deaths
        [...infectedContext.dead.countyCounters, ...infectedContext.dead.unknownCountyCounters].forEach(x => {
            const dateKey = moment(x.date).format(dateFormat);
            dateCasesMap[dateKey].deaths += getTotalFromSnapshot(x.countyCounters);
        });

        const dateCasesArr: {
            cases: number;
            cured: number;
            deaths: number;
        }[] = [];

        dateLabels.forEach((x, i) => {
            const current = Object.assign({}, dateCasesMap[x]);

            if (i === 0) {
                dateCasesArr.push(current);
            } else {
                const prev = Object.assign({}, dateCasesArr[dateCasesArr.length - 1]);

                if (current.cases === 0) {
                    current.cases += prev.cases;
                }

                if (current.cured === 0) {
                    current.cured += prev.cured;
                }

                if (current.deaths === 0) {
                    current.deaths += prev.deaths;
                }

                dateCasesArr.push(current);
            }
        });

        const finalMap: {
            cases: number[];
            cured: number[];
            dead: number[];
        } = {
            cases: [],
            cured: [],
            dead: []
        };

        dateCasesArr.forEach(x => {
            finalMap.cases.push(x.cases);
            finalMap.cured.push(x.cured);
            finalMap.dead.push(x.deaths);
        });

        return finalMap;
    }

    useEffect(() => {
        const dateLabels = getTotalLabels([
            ...infectedContext.cases.countyCounters,
            ...infectedContext.cases.unknownCountyCounters,
            ...infectedContext.cured.countyCounters,
            ...infectedContext.cured.unknownCountyCounters,
            ...infectedContext.dead.countyCounters,
            ...infectedContext.dead.unknownCountyCounters
        ]);

        setDateLabels(dateLabels);

        const dataSetMap = getDataSets(dateLabels);

        setCasesDataset(dataSetMap.cases);
        setCuredDataset(dataSetMap.cured);
        setDeathDataset(dataSetMap.dead);

        if (dataSetMap.cases.length && dataSetMap.dead.length) {
            setAreWidgetsLoading(false);
        }
    }, [infectedContext.cases])

    const dataSets: LineChartDataSet[] = [
        {
            label: "Cazuri",
            color: "#dd2222",
            data: casesDataset
        },
        {
            label: "Vindecati",
            color: "#00db86",
            data: curedDataset
        },
        {
            label: "Decese",
            color: "#0d0d0d",
            data: deathDataset
        }
    ];

    const deadByAge_Males: number[] = [];
    const deadByAge_Females: number[] = [];
    const deadByAge_Unknown: number[] = [];

    // extra dead statistics
    const deadByAgeLabels: string[] = [];

    Object
        .keys(infectedContext.deadByAge.stats)
        .forEach(x => {
            const val = (infectedContext.deadByAge.stats as any)[x as string] as AgeGroupSexStat;

            deadByAgeLabels.push(x);
            deadByAge_Males.push(val.males);
            deadByAge_Females.push(val.females);
            deadByAge_Unknown.push(val.unknown);
        });

    const deadByAgeDataSer: LineChartDataSet[] = [
        {
            label: "Femei",
            borderWidth: 1,
            borderColor: "rgba(255,150,197,1)",
            color: "rgba(255,150,197,0.61)",
            data: deadByAge_Females
        },
        {
            label: "Barbati",
            borderWidth: 1,
            borderColor: "rgba(21,140,186,1)",
            color: "rgba(21,140,186,0.61)",
            data: deadByAge_Males
        },
        {
            label: "Necunoscut",
            borderWidth: 1,
            borderColor: "rgba(0,0,0,1)",
            color: "rgba(0,0,0,0.61)",
            data: deadByAge_Unknown
        }
    ]

    return (
        <div className="corona-page-charts">
            <h1 className="corona-page-charts-title">
                Grafice
            </h1>

            <div className="corona-page-charts-contentwrap">
                <Widget
                    isLoading={areWidgetsLoading}
                    title="Cazuri / Decese">
                    <div className="crona-page-charts-row crona-page-charts-row-single">
                        <LineChart
                            xLabel="Data"
                            chartType="line"
                            yLabel="Cazuri"
                            labels={dateLabels}
                            datasets={dataSets}></LineChart>
                    </div>

                    <div className="crona-page-charts-row crona-page-charts-row-double">
                        <LineChart
                            chartType="line"
                            xLabel="Data"
                            yLabel="Cazuri"
                            labels={dateLabels}
                            datasets={[{
                                label: "Cazuri",
                                color: "#dd2222",
                                data: casesDataset
                            }]} />
                        <LineChart
                            chartType="line"
                            xLabel="Data"
                            yLabel="Decese"
                            labels={dateLabels}
                            datasets={[{
                                label: "Decese",
                                color: "#0d0d0d",
                                data: deathDataset
                            }]} />
                    </div>
                </Widget>

                <Widget
                    isLoading={areWidgetsLoading}
                    title="Decese">
                    <div className="crona-page-charts-row crona-page-charts-row-single">
                        <LineChart
                            yStacked={true}
                            xStacked={true}
                            labels={deadByAgeLabels}
                            datasets={deadByAgeDataSer}
                            xLabel="Varsta"
                            yLabel="Decese"
                            tooltip={{
                                mode: 'index',
                                intersect: false,
                                callbacks: {
                                    footer: (tooltipItems, data) => {
                                        let total = tooltipItems.reduce((a, e: any) => a + parseInt(e.yLabel), 0);
                                        return 'Total: ' + total;
                                    }
                                }
                            }}
                            chartType="bar"></LineChart>
                    </div>
                </Widget>
            </div>
        </div>
    );
}

export default ChartsPage;
