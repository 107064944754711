import React, { useContext } from 'react';
import InfectedMap from '../../components/InfectedMap/InfectedMap';
import InfectedList from '../../components/InfectedList/InfectedList';
import { InfectedContext } from '../../providers/InfectedContext';
import { InfectedData, Infected } from '../../providers/InfectedContextTypes';
import ColPalleteGreens from "../../data/col-palette-greens";
import "./Cured.css";
import { InfectedListSorter } from '../../components/InfectedList/InfectedListTypes';

const CuredPage: React.FC = () => {
    const infectedContext = useContext<InfectedData>(InfectedContext);
    const greenCols = {
        colors: ColPalleteGreens
    };
    const listSorters: InfectedListSorter<Infected>[] = [
        {
            key: "cronologic",
            text: "Cronologic",
            sortFn: (a, b) => a.id - b.id,
            isActive: true
        }
    ];

    return (
        <div className="corona-page-home-wrap">
            <div className="corona-page-home-infected-map-wrap">
                <InfectedMap
                    data={infectedContext.cured}
                    colPalette={greenCols}
                />
            </div>

            <div className="corona-page-home-caselist-wrap">
                <InfectedList
                    data={infectedContext.cured.entries}
                    sorters={listSorters}
                />
            </div>
        </div>
    );
}

export default CuredPage;
