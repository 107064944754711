import "./LineChart.css";
import React, { useRef, useEffect } from 'react';
import { Chart } from "chart.js";
import { LineChartDataSet } from "./LineChartTypes";

const LineChart: React.FC<LineChartProps> = (props) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const chart = useRef<Chart>();

    // destroy
    useEffect(() => {
        return () => {
            if (chart && chart.current) {
                chart.current.destroy();
            }
        }
    }, []);

    useEffect(() => {
        if (!(props.datasets && props.datasets.length)) {
            return;
        }

        if (!canvasRef.current) {
            return;
        }

        const canvasCtx = canvasRef.current.getContext("2d");

        if (!canvasCtx) {
            return;
        }

        if (chart && chart.current) {
            chart.current.destroy();
        }

        const mappedDataSets = props.datasets.map(d => {
            const adapted: Chart.ChartDataSets = {};

            if (d.borderColor) {
                adapted.borderColor = d.borderColor;
            }

            if (d.borderWidth) {
                adapted.borderWidth = d.borderWidth;
            }

            adapted.label = d.label;
            adapted.backgroundColor = d.bgCols ? d.bgCols : d.color;
            adapted.borderColor = d.color;
            adapted.data = d.data;
            adapted.fill = false;

            return adapted;
        });

        const chartOpts: Chart.ChartConfiguration = {
            type: props.chartType,
            data: {
                labels: props.labels,
                datasets: mappedDataSets
            },
            options: {
                // responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: {
                    mode: 'nearest',
                    intersect: true
                },
                scales: {
                    xAxes: [{
                        stacked: !!props.xStacked,
                        display: true,
                        scaleLabel: {
                            display: false,
                            labelString: props.xLabel
                        }
                    }],
                    yAxes: [{
                        stacked: !!props.yStacked,
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: props.yLabel
                        }
                    }]
                }
            }
        };

        if (props.tooltip) {
            chartOpts.options!.tooltips = props.tooltip;
        }

        chart.current = new Chart(canvasCtx, chartOpts);

    }, [props.datasets]);

    return (
        <div className="corona-line-chart-wrap">
            <canvas
                className="corona-line-chart-canvas"
                ref={canvasRef}></canvas>
        </div>
    );
}

export interface LineChartProps {
    labels: string[];
    datasets: LineChartDataSet[];
    xLabel: string;
    yLabel: string;
    xStacked?: boolean;
    yStacked?: boolean;
    tooltip?: Chart.ChartTooltipOptions;
    chartType: "line" | "bar";
}

export default LineChart;