import "./App.css";
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { loadSpreadsheet } from './util/spreadsheet';
import { Infected, Sex, Source, InfectedData, DateCountyCounters, DATE_FORMAT, CaseData, DeadByAge } from './providers/InfectedContextTypes';
import { InfectedContext } from './providers/InfectedContext';
import HomePage from './pages/Home/Home';
import RoCounties from "./data/county-naming";
import { initializeIcons } from '@uifabric/icons';
import moment from 'moment';
import DeadPage from "./pages/Dead/Dead";
import ChartsPage from "./pages/Charts/Charts";
import NavMenu from "./components/NavMenu/NavMenu";
import CuredPage from "./pages/Cured/Cured";

initializeIcons();

function App() {
  const [infectedState, setInfectedState] = useState<InfectedData>(new InfectedData());

  function promiseSpreadsheet(sheetName: string) {
    return new Promise((resolve) => {
      loadSpreadsheet(resolve, sheetName);
    });
  }

  useEffect(() => {
    Promise.all([
      promiseSpreadsheet("Cazuri"),
      promiseSpreadsheet("Morti"),
      promiseSpreadsheet("Vindecati")
    ]).then((results) => {
      mapInfectedData(results[0] as any[], results[1] as any[], results[2] as any[]);
    });
  }, []);

  function initCountyCountersForDateIfRequired(date: string, mainObj: {
    [key: string]: {
      [key: string]: number
    }
  }, isUnknown: boolean = false) {
    if (typeof mainObj[date] === "object") {
      return;
    }

    const counterData: { [key: string]: number } = {};

    if (!isUnknown) {
      Object
        .keys(RoCounties)
        .forEach(countyCode => {
          counterData[countyCode] = 0;
        });
    } else {
      counterData["NA"] = 0;
    }

    mainObj[date] = counterData;
  }

  function aggregateCountyCounters(countyCounters: {
    [key: string]: {
      [key: string]: number
    }
  }) {
    const dateCountyCounters = Object
      .keys(countyCounters)
      .sort((a, b) => {
        return moment(a, DATE_FORMAT).valueOf() - moment(b, DATE_FORMAT).valueOf();
      })
      .map(x => {
        const counter = new DateCountyCounters(moment(x, DATE_FORMAT).toDate());
        counter.countyCounters = countyCounters[x];

        return counter;
      })

    for (let i = 0; i < dateCountyCounters.length; i++) {
      const current = dateCountyCounters[i];

      if (i === 0) {
        continue;
      }

      const prev = dateCountyCounters[i - 1];

      Object.keys(current.countyCounters).forEach(c => {
        current.countyCounters[c] += prev.countyCounters[c];
      });
    }

    return dateCountyCounters;
  }

  function mapCaseType(events: any[], cb?: (infected: Infected) => void) {
    const casesMapped: Infected[] = [];

    // {
    //   "Nr. Crt.": "1",
    //   "Judet": "GJ",
    //   "Data": "26.02.2020",
    //   "Sex": "M",
    //   "Legatura": "-",
    //   "Varsta": "25",
    //   "Detalii": "A avut legatura cu patronul italian",
    //   "Sursa 1": "https://www.digi24.ro/stiri/actualitate/primul-caz-de-coronavirus-in-romania-1266806"
    // }

    const infectorData = {} as { [key: string]: number; }
    const dateCountyCounters: {
      [key: string]: {
        [key: string]: number
      }
    } = {};
    const unknownDateCountyCounters: {
      [key: string]: {
        [key: string]: number
      }
    } = {};

    events && events.forEach(x => {
      const infect = {} as Infected;

      const dataSex = ((x["Sex"] || "") as string).trim().toLowerCase();
      const dataLinks: number[] = [];
      const dataLinkStr = ((x["Legatura"] || "") as string).trim();
      const dataAge = parseInt(((x["Varsta"] || "") as string).trim(), 10);
      const dataSources: Source[] = [];

      for (let i = 0; i < 5; i++) {
        let currentSourceLink = (x["Sursa " + (i + 1)] || "") as string;
        currentSourceLink = currentSourceLink.trim();

        if (!currentSourceLink) {
          break;
        }

        dataSources.push({
          url: currentSourceLink
        } as Source);
      }

      if (dataLinkStr && dataLinkStr.indexOf("-") === -1) {
        dataLinkStr.split(",").forEach(x => {
          dataLinks.push(parseInt(x.trim()));

          if (typeof infectorData[x] === "undefined") {
            infectorData[x] = 0;
          }

          infectorData[x] += 1;
        });
      }

      infect.id = parseInt(x["Nr. Crt."], 10);
      infect.county = (x["Judet"] || "").trim();
      infect.dateReported = x["Data"];
      infect.sex = (dataSex === "m" || dataSex === "b") ? Sex.Male : dataSex === "f" ? Sex.Female : Sex.Unknown;
      infect.link = dataLinks;
      infect.age = isNaN(dataAge) ? undefined : dataAge;
      infect.description = ((x["Detalii"] || "") as string).trim();
      infect.sources = dataSources;

      const caseDate = infect.dateReported.toString();
      initCountyCountersForDateIfRequired(caseDate, dateCountyCounters);

      if (infect.county) {
        dateCountyCounters[caseDate][infect.county] += 1;
      } else {
        initCountyCountersForDateIfRequired(caseDate, unknownDateCountyCounters, true);
        unknownDateCountyCounters[caseDate]["NA"] += 1;
      }

      if (typeof cb === "function") {
        cb(infect);
      }

      casesMapped.push(infect);
    });

    casesMapped.forEach(m => {
      const infCount = infectorData[m.id.toString()];

      if (typeof infCount === "number") {
        m.infectorCount = infCount
      } else {
        m.infectorCount = 0;
      }
    });

    const caseData = new CaseData();

    caseData.entries = casesMapped;
    caseData.countyCounters = aggregateCountyCounters(dateCountyCounters);
    caseData.unknownCountyCounters = aggregateCountyCounters(unknownDateCountyCounters);

    return caseData;
  }

  function mapInfectedData(cases: any[], deaths: any[], cured: any[]) {
    const deadByAge = new DeadByAge();

    const infectionCaseData = mapCaseType(cases);
    const deathCaseData = mapCaseType(deaths, (infected: Infected) => {
      if (infected && typeof infected.age === "number") {
        deadByAge.assignAge(infected);
      }
    });
    const curedCaseData = mapCaseType(cured);

    setInfectedState({
      cases: infectionCaseData,
      dead: deathCaseData,
      cured: curedCaseData,
      deadByAge
    });
  }

  return (
    <Router>
      <InfectedContext.Provider value={infectedState}>
        <div className="corona-app-container">
          <Route path="/" exact component={HomePage} />
          <Route path="/vindecati" exact component={CuredPage} />
          <Route path="/morti" exact component={DeadPage} />
          <Route path="/grafice" exact component={ChartsPage} />

          <NavMenu menuItems={[
            { title: "Cazuri", ruta: "/" },
            { title: "Vindecati", ruta: "/vindecati" },
            { title: "Decese", ruta: "/morti" },
            { title: "Grafice", ruta: "/grafice" }
          ]} />
        </div>
      </InfectedContext.Provider>
    </Router>
  );
}

export default App;
