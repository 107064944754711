import React, { useState, useEffect } from 'react';
import { Infected } from '../../providers/InfectedContextTypes';
import "./InfectedList.css";
import { Dropdown, IDropdownStyles, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import InfectedListItem from './InfectedListItem/InfectedListItem';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { InfectedListSorter } from './InfectedListTypes';
import InfectedListShimmer from './InfectedListShimmer/InfectedListShimmer';

const InfectedList: React.FC<InfectedListProps> = (props) => {
    const pageCount = 100;

    const [sorted, setSorted] = useState<Infected[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [fwdEnabled, setFwdEnabled] = useState<boolean>(true);
    const [backEnabled, setBackEnabled] = useState<boolean>(true);
    const [infectedList, setInfectedList] = useState<Infected[]>([]);

    const [sortingOptions, setSortingOptions] = useState<IDropdownOption[]>([]);
    const [selectedSortOption, setSelectedSortOption] = useState<string>();

    function take(skip: number, limit: number) {
        return sorted.slice(skip, skip + limit);
    }

    function count() {
        return sorted.length;
    }

    useEffect(() => {
        setSorted(props.data);

        let activeSortKey = "";
        const dropdownOpts: IDropdownOption[] = [];

        for (let i = 0; i < props.sorters.length; i++) {
            const srt = props.sorters[i];

            if (srt.isActive && !activeSortKey) {
                activeSortKey = srt.key;
            }

            dropdownOpts.push({
                text: srt.text,
                key: srt.key
            });
        }

        setSortingOptions(dropdownOpts);

        if (!activeSortKey && props.sorters && props.sorters[0]) {
            activeSortKey = props.sorters[0].key;
        }

        setSelectedSortOption(activeSortKey);

    }, [props.data, props.sorters]);

    useEffect(() => {
        setInfectedList(take((currentPage - 1) * pageCount, pageCount));
    }, [sorted]);

    useEffect(() => {
        setFwdEnabled(currentPage !== Math.ceil(count() / pageCount));
        setBackEnabled(currentPage > 1);
    }, [currentPage, sorted])

    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: "100%" }
    };

    function onSortDropdownChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) {
        if (!option) {
            return;
        }

        let s: Infected[] = [];
        const foundSorterIndex = props.sorters.findIndex(srt => srt.key === option.key);

        if (foundSorterIndex === -1) {
            return;
        }

        s = sorted.sort(props.sorters[foundSorterIndex].sortFn);

        if (s && s.length) {
            setSorted([...s]);
            setSelectedSortOption(option.key.toString());
        }
    }

    function navigate(forward: boolean) {
        if (forward) {
            setInfectedList(take(currentPage * pageCount, pageCount));
            setCurrentPage(currentPage + 1);
        } else {
            setInfectedList(take((currentPage - 2) * pageCount, pageCount));
            setCurrentPage(currentPage - 1);
        }
    }

    if (!props.data.length) {
        return (
            <InfectedListShimmer amount={100} />
        );
    }

    return (
        <div className="corona-infected-list">
            <div className="corona-infected-list-header">
                <Dropdown
                    styles={dropdownStyles}
                    onChange={onSortDropdownChange}
                    options={sortingOptions}
                    defaultSelectedKey={selectedSortOption}
                ></Dropdown>
            </div>

            <div className="corona-infected-list-scroller">
                {infectedList.map((elem) => {
                    return (
                        <InfectedListItem
                            key={elem.id}
                            {...elem}
                            showDetails={false}
                        ></InfectedListItem>
                    );
                })}
            </div>

            <div className="corona-infected-list-footer">
                <div className="corona-infected-list-paginator">
                    <span
                        tabIndex={0}
                        data-enabled={backEnabled}
                        onClick={() => { navigate(false) }}
                        className="corona-infected-list-paginator-bttn noselect">
                        <Icon
                            iconName="ChevronLeft"
                            className="" />
                    </span>
                    <span
                        className="corona-infected-list-paginator-bttn noselect"
                        data-enabled={fwdEnabled}
                        tabIndex={0}
                        onClick={() => { navigate(true) }}>
                        <Icon
                            iconName="ChevronRight"
                            className="" />
                    </span>
                </div>
                <div className="corona-infected-list-footer-counters">
                    <span>
                        <strong>Total:</strong> {props.data.length}
                    </span>
                </div>
            </div>
        </div>
    );
}

export interface InfectedListProps {
    data: Infected[];
    sorters: InfectedListSorter<Infected>[];
}

export default InfectedList;