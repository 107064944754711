import React, { useState } from 'react';
import { Infected, Sex, Source } from '../../../providers/InfectedContextTypes';
import ContyNames from "./../../../data/county-naming";
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import "./InfectedListItem.css";

const InfectedListItem: React.FC<InfectedListItemProps> = (props) => {
    const [isDescriptionVisible, setDescVisible] = useState<boolean>(props.showDetails);

    function getAvatar(sex: Sex) {
        if (sex === Sex.Male) {
            return "https://semantic-ui.com/images/avatar/small/christian.jpg";
        } else if (sex === Sex.Female) {
            return "https://semantic-ui.com/images/avatar/small/helen.jpg";
        } else {
            return "https://semantic-ui.com/images/wireframe/square-image.png";
        }
    }

    function renderSources(sources: Source[]) {
        return (
            sources.map((elem, index) => {
                const separator = index + 1 === sources.length ? "" : <span className="cornoa-infectedlist-elem-source-separator">|</span>;

                return (
                    <span
                        key={elem.url}
                        className="corona-infected-list-elem-link-anchor-wrap">
                        <a className="corona-infected-list-elem-link-anchor"
                            rel="noopener noreferrer"
                            target="_blank"
                            href={elem.url}>
                            Sursa {index + 1}
                        </a>

                        {separator}
                    </span>
                )
            })
        )
    }

    function formatCounty(countyCode: string) {
        return [
            (ContyNames as any)[countyCode] as string,
            " ",
            "(",
            countyCode,
            ")"
        ].join("");
    }

    function renderInfectorCount(infected: Infected) {
        if (!infected.infectorCount) {
            return null;
        }

        return (
            <span className="corona-infected-list-elem-infector-count">
                A infectat <strong>{infected.infectorCount}</strong>.
            </span>
        );
    }

    function onDescTogglerClick(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
        setDescVisible(!isDescriptionVisible);
    }

    let footerClassName = "corona-infected-list-elem-footer";

    if (props.sources && props.sources.length) {
        footerClassName = [footerClassName, "hidden"].join(" ");
    }

    return (
        <div key={props.id} className="corona-infected-list-elem">
            <div className="corona-infected-list-elem-top-section-wrap">
                <div className="corona-infected-list-elem-avatar-wrap">
                    <img className="corona-infected-list-elem-avatar" src={getAvatar(props.sex)} alt="profilepic" />

                    <div className="corona-infected-list-elem-avatar-counter">
                        {props.id}
                    </div>
                </div>
                <div className="corona-infected-list-elem-top-section-right">
                    <div className="corona-infected-list-elem-top-section-upper">
                        <span>
                            {props.age || "?"} ani
                                    </span>

                        <span className="corona-infected-list-elem-date-reported">
                            {props.dateReported}
                        </span>
                    </div>

                    <div className="corona-infected-list-elem-top-section-mid">
                        <span>
                            {formatCounty(props.county)}
                        </span>

                        {renderInfectorCount(props)}
                    </div>
                </div>
            </div>

            <div className="corona-infected-list-elem-description" data-shown={isDescriptionVisible}>
                {props.description}
            </div>

            <div className={footerClassName}>
                {renderSources(props.sources)}

                <span
                    onClick={onDescTogglerClick}
                    title={`${isDescriptionVisible ? "Ascunde" : "Arata"} detalii..`}
                    className="corona-infected-list-elem-desc-toggler"
                    tabIndex={0}>
                    <Icon
                        style={{color: isDescriptionVisible? "#ec0021" : "#00dc11"}}
                        iconName={isDescriptionVisible ? "CalculatorSubtract" : "CalculatorAddition"}
                        className="" />
                </span>
            </div>
        </div>
    );
}

export interface InfectedListItemProps extends Infected {
    showDetails: boolean;
}

export default InfectedListItem;