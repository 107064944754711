import Color from "color";

const baseCol = "#ffdcdb";
let step = 0;
const reds = Array.apply(null, Array(43)).map(x => {
    const newCol = Color(baseCol).darken(step).hex();
    step = step + 0.02;

    return newCol;
})

export default reds;

// export default [
//     "#f5c1c1",
//     "#f3b4b4",
//     "#f1a7a7",
//     "#ef9999",
//     "#ed8c8c",
//     "#eb7f7f",
//     "#e97272",
//     "#e76464",
//     "#e55757",
//     "#e34a4a",
//     "#e13d3d",
//     "#df2f2f",
//     "#dd2222",
//     "#d02020",
//     "#c21e1e",
//     "#b51c1c",
//     "#a81a1a",
//     "#9b1818",
//     "#8d1616",
//     "#801414",
//     "#731212",
//     "#661010",
//     "#580e0e",
//     "#4b0c0c",
//     "#3e0a0a"
// ];

// export default [
//     "#f5c1c1",
//     "#f3b4b4",
//     "#f1a7a7",
//     "#ef9999",
//     "#ed8c8c",
//     "#eb7f7f",
//     "#e97272",
//     "#e76464",
//     "#e55757",
//     "#e34a4a",
//     "#e13d3d",
//     "#df2f2f",
//     "#dd2222",
//     "#d02020",
//     "#c21e1e",
//     "#b51c1c",
//     "#a81a1a",
//     "#9b1818",
//     "#8d1616",
//     "#801414",
//     "#731212",
//     "#661010",
//     "#580e0e",
//     "#4b0c0c",
//     "#3e0a0a"
// ];