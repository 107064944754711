import React, { useRef, useEffect, useState } from 'react';
import RoCounties from './../../data/ro-counties.json';
import "./InfectedMap.css";
import CountyNames from "./../../data/county-naming";
import leaflet, { LeafletMouseEvent } from "leaflet";
import 'leaflet/dist/leaflet.css'
import { Slider } from 'office-ui-fabric-react/lib/Slider';
import InfectedMapTooltip, { InfectedMapTooltipInfo } from './InfectedMapTooltip';
import moment from 'moment';
import { CaseData } from '../../providers/InfectedContextTypes.js';
import InfectedMapShimmer from './InfectedMapShimmer/InfectedMapShimmer';

export type ColRangeMap = { [key: string]: string; };

const InfectedMap: React.FC<InfectedMapProps> = (props) => {
    const id = useRef("a" + Math.random().toString(36).substring(7));
    const resizeTimeoutId = useRef<number>(0);

    const leafletElement = useRef<leaflet.Map>();
    const geoJsonLeaflet = useRef<leaflet.GeoJSON<any>>();

    const [currentUnknownCases, setCurrentUnknownCases] = useState<number>(0);
    const [dateSliderValue, setDateSliderValue] = useState<number>(0);
    const [colorRangeMap, setColorRangeMap] = useState<ColRangeMap>({});
    const [currentCountyInfo, setCurrentCountyInfo] = useState<InfectedMapTooltipInfo>({});
    const [infectionDates, setInfectionDates] = useState<number[]>([]);

    function updateSize() {
        clearTimeout(resizeTimeoutId.current);
        resizeTimeoutId.current = window.setTimeout(() => {
            if (!(leafletElement && leafletElement.current)) {
                return;
            }

            const winW = window.innerWidth;

            if (winW > 1175) {
                leafletElement.current.setZoom(7);
            }

            if (winW <= 1175) {
                leafletElement.current.setZoom(6);
            }
        }, 100);
    }

    function createRangeMap(min: number, max: number) {
        let colAmount = 0;

        props.colPalette.colors.forEach(x => {
            colAmount += 1;
        });

        const delta = max - min;
        const incrementor = delta / colAmount;
        let step = min;
        const colMap: ColRangeMap = {};

        props.colPalette.colors.forEach((x, i) => {
            const newStep = step + incrementor;
            const key = [i === 0 ? 1 : Math.round(step), "-", Math.round(newStep)].join("");
            colMap[key] = x;
            step = newStep;
        });

        return colMap;
    }

    function getColor(total: number, colMap: ColRangeMap) {
        const keys = Object.keys(colMap);

        if (total === 0) {
            return "#ffffff";
        }

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];

            const rangeStrSplit = key.split("-");
            const rangeMin = parseFloat(rangeStrSplit[0]);
            const rangeMax = parseFloat(rangeStrSplit[1]);

            if (total >= rangeMin && total <= rangeMax) {
                return colMap[key];
            }
        }

        return "#ffffff";
    }

    function enrichGeoJsonWithCountryCounters(geoJson: any, countyCounters: { [key: string]: number; }) {
        let min: number | undefined;
        let max: number | undefined;

        geoJson.features.forEach((x: any) => {
            const cCode = x.properties.mnemonic;

            (x as any).id = cCode;

            if (!countyCounters[cCode]) {
                (x as any).total = 0;
            } else {
                (x as any).total = countyCounters[cCode];
            }

            const total = (x as any).total;

            if (typeof min === "undefined") {
                min = total;
            }

            if (typeof max === "undefined") {
                max = total;
            }

            if (total < min!) {
                min = total;
            }

            if (total > max!) {
                max = total;
            }
        });

        min = typeof min !== "undefined" ? min : 0;
        max = typeof max !== "undefined" ? max : 0;

        return {
            min, max
        };
    }

    function tempGetSliderPos() {
        return parseInt((document as any).querySelector(".corona-infected-slider-wrap .ms-Slider-slideBox").getAttribute("aria-valuetext"), 10);
    }

    function onLayerMouseEnter(e: LeafletMouseEvent, cMap: ColRangeMap, sliderPos: number) {
        const layer = e.target;

        const countyName = layer.feature.properties.mnemonic;
        const counter = props.data.countyCounters[sliderPos].countyCounters;
        const countyTotal = counter[countyName];
        const color = getColor(countyTotal, cMap);

        layer.setStyle({
            fillColor: color,
            weight: 2,
            color: '#666',
            dashArray: '',
            fillOpacity: 0.7
        });

        if (!leaflet.Browser.ie && !leaflet.Browser.opera && !leaflet.Browser.edge) {
            layer.bringToFront();
        }

        setCurrentCountyInfo({
            county: (CountyNames as any)[countyName],
            total: countyTotal ? countyTotal : 0,
            color
        });
    }

    function onLayerMouseLeave(e: LeafletMouseEvent, cMap: ColRangeMap, sliderPos: number) {
        if (!geoJsonLeaflet.current) {
            return;
        }

        // geoJsonLeaflet
        //     .current
        //     .resetStyle();

        const color = getColor(props.data.countyCounters[sliderPos].countyCounters[e.target.feature.id], cMap);

        (e.target as any)
            .setStyle(getBaseLayerStyles(color))

        setCurrentCountyInfo({});
    }

    function onLayerMouseClick(e: LeafletMouseEvent, cMap: ColRangeMap, sliderPos: number) {
        if (!geoJsonLeaflet.current) {
            return;
        }

        const existingLayers = geoJsonLeaflet
            .current
            .getLayers();

        for (let i = 0; i < existingLayers.length; i++) {
            const exLayer = existingLayers[i];
            const color = getColor(props.data.countyCounters[sliderPos].countyCounters[(exLayer as any).feature.id], cMap);
            (exLayer as any).setStyle(getBaseLayerStyles(color));
        }

        onLayerMouseEnter(e, cMap, sliderPos);
    }

    function getBaseLayerStyles(color: string) {
        return {
            fillColor: color,
            weight: 2,
            opacity: 1,
            color: 'white',
            dashArray: '1',
            fillOpacity: 0.7
        };
    }

    function hasData() {
        return props.data && props.data.entries && props.data.entries.length;
    }

    useEffect(() => {
        if (!hasData()) {
            return;
        }

        const geoJsonData = RoCounties;
        const latestCounter = props.data.countyCounters[props.data.countyCounters.length - 1];
        const infectionTimestamps = props.data.countyCounters.map(cc => {
            return moment(cc.date).valueOf();
        });

        const latestUnknownCountyCounters = props.data.unknownCountyCounters[props.data.unknownCountyCounters.length - 1];
        setCurrentUnknownCases(
            latestUnknownCountyCounters && typeof latestUnknownCountyCounters.countyCounters["NA"] === "number" ?
                latestUnknownCountyCounters.countyCounters["NA"] : 0
        );

        setInfectionDates(infectionTimestamps);
        setDateSliderValue(infectionTimestamps.length - 1);
        const minMax = enrichGeoJsonWithCountryCounters(geoJsonData, latestCounter.countyCounters);
        const colMap = createRangeMap(minMax.min, minMax.max);
        setColorRangeMap(colMap);

        geoJsonData.features.forEach((x: any) => {
            x.color = getColor(x.total, colMap);
        });

        leafletElement.current = leaflet.map(id.current, {
            attributionControl: false,
            zoomControl: false
        }).setView([45.7909, 24.7731], 7);

        leaflet
            .tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', { attribution: '' })
            .addTo(leafletElement.current);

        geoJsonLeaflet.current = leaflet
            .geoJSON(RoCounties as any, {
                style: (d) => {
                    return getBaseLayerStyles((d as any).color);
                },
                onEachFeature: (feature, layer) => {
                    layer.on({
                        mouseover: (e: LeafletMouseEvent) => {
                            onLayerMouseEnter(e, colMap, tempGetSliderPos());
                        },
                        mouseout: (e: LeafletMouseEvent) => {
                            onLayerMouseLeave(e, colMap, tempGetSliderPos());
                        },
                        click: (e: LeafletMouseEvent) => {
                            onLayerMouseClick(e, colMap, tempGetSliderPos());
                        }
                    });
                }
            });

        geoJsonLeaflet.current
            .addTo(leafletElement.current);

        updateSize();
    }, [props.data]);

    function onSliderChange(val: number) {
        setDateSliderValue(val);
        setCurrentCountyInfo({});

        let unknownCases = 0;

        if (props.data.unknownCountyCounters.length) {
            const sliderTimestamp = infectionDates[val];
            const unknownCaseIndex = props.data.unknownCountyCounters.findIndex(x => x.date.valueOf() === sliderTimestamp);

            if (unknownCaseIndex > -1) {
                unknownCases = props.data.unknownCountyCounters[unknownCaseIndex].countyCounters["NA"];
            }
        }

        setCurrentUnknownCases(unknownCases);

        if (!geoJsonLeaflet.current) {
            return;
        }

        geoJsonLeaflet
            .current
            .getLayers()
            .forEach(layer => {
                const cCode = (layer as any).feature.properties.mnemonic as string;
                const cTotal = props.data.countyCounters[val].countyCounters[cCode];
                const color = getColor(cTotal, colorRangeMap);

                (layer as any).setStyle(getBaseLayerStyles(color))
            });
    }

    if (!hasData()) {
        return (
            <InfectedMapShimmer />
        );
    }

    return (
        <div className="corona-infected-map-wrap">
            <div className="corona-infected-map-mapwrap">
                <div id={id.current}
                    className="corona-infected-map-leaflet"></div>
            </div>

            <div className="corona-infected-slider-wrap">
                <Slider
                    min={0}
                    max={infectionDates.length - 1}
                    value={dateSliderValue}
                    onChange={onSliderChange}
                    showValue={false}
                />

                <div className="corona-infected-slider-date">
                    {moment(infectionDates[dateSliderValue]).format("DD.MM.YYYY")}
                </div>
            </div>

            <InfectedMapTooltip
                info={currentCountyInfo}
                unknownCases={currentUnknownCases}
            ></InfectedMapTooltip>
        </div>
    );
}

export interface InfectedMapProps {
    data: CaseData;
    colPalette: { colors: string[] }
}

export default InfectedMap;