import React, { useState, useRef, useEffect } from 'react';
import "./Widget.css";
import WidgetButton from './WidgetButton/WidgetButton';
import Preloader from './WidgetPreloader/WidgetPreloader';

const Widget: React.FC<WidgetProps> = (props) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(true);
    const [isExpandedTruly, setIsExpandedTruly] = useState<boolean>(true);
    const toggleTimeoutId = useRef(0);

    function toggle() {
        const shouldExpand = !isExpanded;
        setIsExpanded(shouldExpand);
    }

    useEffect(() => {
        return () => {
            clearTimeout(toggleTimeoutId.current);
        }
    }, []);

    useEffect(() => {
        clearTimeout(toggleTimeoutId.current);
        toggleTimeoutId.current = window.setTimeout(() => {
            setIsExpandedTruly(isExpanded);
        }, 201);
    }, [isExpanded]);

    return (
        <div className={`corona-widget-wrap ${isExpanded ? "corona-widget-wrap-toggled" : "corona-widget-wrap-not-toggled"}`}>
            <div className="corona-widget-header">
                <div className="corona-widget-header-title">
                    {props.title}
                </div>

                <div className="corona-widget-header-toolbar">
                    <WidgetButton
                        onClick={toggle}
                        extraCssClass={isExpanded ? "corona-toggled" : "corona-not-toggled"}
                        title="Toggle" />
                </div>
            </div>

            <div
                className="corona-widget-body"
                data-shown={isExpandedTruly ? "true" : "false"}>
                <Preloader
                    isVisible={props.isLoading} />
                {props.children}
            </div>
        </div>
    );
}

export interface WidgetProps {
    title: string;
    isLoading: boolean;
}

export default Widget;