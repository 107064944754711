import React from 'react';
import "./InfectedMapShimmer.css";
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';

const InfectedMapShimmer: React.FC<InfectedMaperProps> = (props) => {
    return (
        <div className="corona-infected-map-shimmer-wrap">
            <div className="corona-infected-map-shimmer-inner">
                <ProgressIndicator />
            </div>
        </div>
    );
}

export interface InfectedMaperProps {
}

export default InfectedMapShimmer;