export interface InfectedContext {

}

export const DATE_FORMAT = "DD.MM.YYYY";

export enum Sex {
    Male = "M",
    Female = "F",
    Unknown = "U"
}

export interface Source {
    url: string;
}

export interface Infected {
    id: number;
    county: string;
    dateReported: Date;
    sex: Sex;
    link: number[];
    age?: number;
    description: string;
    sources: Source[];
    infectorCount: number;
}

export class DateCountyCounters {
    public countyCounters: { [key: string]: number; } = {};

    constructor(public date: Date) {

    }
}

export class CaseData {
    public entries: Infected[] = [];
    public countyCounters: DateCountyCounters[] = [];
    public unknownCountyCounters: DateCountyCounters[] = [];
}

export class AgeGroupSexStat {
    constructor(public males: number = 0, public females: number = 0, public unknown: number = 0) {

    }

    public increment(sex: Sex) {
        if (sex === Sex.Female) {
            this.females += 1;
        } else if (sex === Sex.Male) {
            this.males += 1;
        } else {
            this.unknown += 1;
        }
    }

    public getTotal() {
        return this.males + this.females + this.unknown;
    }
}

export class DeadByAge {
    public stats: {
        "0-9": AgeGroupSexStat;
        "10-19": AgeGroupSexStat;
        "20-29": AgeGroupSexStat;
        "30-39": AgeGroupSexStat;
        "40-49": AgeGroupSexStat;
        "50-59": AgeGroupSexStat;
        "60-69": AgeGroupSexStat;
        "70-79": AgeGroupSexStat;
        "80+": AgeGroupSexStat;
    } = {
            "0-9": new AgeGroupSexStat(),
            "10-19": new AgeGroupSexStat(),
            "20-29": new AgeGroupSexStat(),
            "30-39": new AgeGroupSexStat(),
            "40-49": new AgeGroupSexStat(),
            "50-59": new AgeGroupSexStat(),
            "60-69": new AgeGroupSexStat(),
            "70-79": new AgeGroupSexStat(),
            "80+": new AgeGroupSexStat()
        };

    public assignAge(infected: Infected) {
        const age = infected.age;
        const sex = infected.sex;

        if (typeof age !== "number") {
            return;
        }

        if (age >= 80) {
            this.stats["80+"].increment(sex);
        } else if (age >= 70 && age <= 79) {
            this.stats["70-79"].increment(sex);
        } else if (age >= 60 && age <= 69) {
            this.stats["60-69"].increment(sex);
        } else if (age >= 50 && age <= 59) {
            this.stats["50-59"].increment(sex);
        } else if (age >= 40 && age <= 49) {
            this.stats["40-49"].increment(sex);
        } else if (age >= 30 && age <= 39) {
            this.stats["30-39"].increment(sex);
        } else if (age >= 20 && age <= 29) {
            this.stats["20-29"].increment(sex);
        } else if (age >= 10 && age <= 19) {
            this.stats["10-19"].increment(sex);
        } else {
            this.stats["0-9"].increment(sex);
        }
    }
}

export class InfectedData {
    public cases: CaseData = new CaseData();
    public dead: CaseData = new CaseData();
    public cured: CaseData = new CaseData();
    public deadByAge: DeadByAge = new DeadByAge();
}