import React from 'react';
import "./InfectedListShimmer.css";
import { Shimmer } from 'office-ui-fabric-react/lib/Shimmer';

const InfectedListShimmer: React.FC<InfectedListShimmerProps> = (props) => {
    return (
        <div className="corona-infected-list-shimmer-wrap">
            {Array.apply(null, Array(100)).map((elem, idx) => {
                return (
                    <Shimmer
                        key={idx}
                        className="corona-infected-list-shimmer-piece"></Shimmer>
                );
            })}
        </div>
    );
}

export interface InfectedListShimmerProps {
    amount: number;
}

export default InfectedListShimmer;