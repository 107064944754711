import React from 'react';
import "./WidgetButton.css";
import { Icon } from 'office-ui-fabric-react/lib/Icon';

const WidgetButton: React.FC<WidgetButtonProps> = (props) => {
    let wrapClass = "corona-widget-header-toolbar-bttn";

    if ((props.extraCssClass || "").trim()) {
        wrapClass = `${wrapClass} ${[props.extraCssClass]}`
    }

    return (
        <button
            type="button"
            onClick={props.onClick}
            className={wrapClass}>
            <Icon
                iconName="ChevronUp"
                className="corona-widget-header-toolbar-bttn-icon" />
        </button>
    );
}

export interface WidgetButtonProps {
    title: string;
    extraCssClass?: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default WidgetButton;