import React, { useState, useRef, useEffect } from 'react';
import "./WidgetPreloader.css";
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';

const WidgetPreloader: React.FC<WidgetPreloaderProps> = (props) => {
    const timeoutId = useRef(0);
    const [isVisibleTruly, setIsVisibleTruly] = useState<boolean>(true);

    useEffect(() => {
        return () => {
            clearTimeout(timeoutId.current);
        }
    }, []);

    useEffect(() => {
        clearTimeout(timeoutId.current);
        timeoutId.current = window.setTimeout(() => {
            setIsVisibleTruly(props.isVisible);
        }, 201);
    }, [props.isVisible]);

    const visibilityStateClass = props.isVisible ? "corona-preloader-visible" : "corona-preloader-not-visible";

    return (
        <div className={`corona-widget-preloader-wrap ${visibilityStateClass}`}
            data-shown={isVisibleTruly ? "true" : "false"}>
            <div className="corona-widget-preloader-wrap-inner">
                <ProgressIndicator />
            </div>
        </div>
    );
}

export interface WidgetPreloaderProps {
    isVisible: boolean;
}

export default WidgetPreloader;